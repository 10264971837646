import React, { useState } from 'react'
import './App.css';
import SheetDB from 'sheetdb-js'
import Swal from 'sweetalert2';

const Landlord = () => {

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [apartment, setApartment] = useState("")
  const [city, setCity] = useState("")
  const [province, setProvince] = useState("")
  const [zip, setZip] = useState("")
  const [hat, setHat] = useState("")

  // generating current date
  let objectDate = new Date();

  let month = objectDate.getMonth() + 1;

  let year = objectDate.getFullYear();

  let currentMonthYear = `${month}/${year}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName, lastName, email, address, city, province, zip, hat == "") {
      Swal.fire(
        'Error!',
        'Fields marked with "*" are mandatory',
        'error'
      )
    }

    // Generating OTP
    let generatedOTP = Math.floor(Math.random() * 10000);


    //generating email body
    let emailBody = `
    <h1 style="text-align: center">Luckyland Slots</h1>
<img src="https://i.ibb.co/HTL87Dw/Luckyland-2.png" alt='Logo' style="height: auto; width: 40vw; display: block; margin-left: auto; margin-right: auto; width: 50%;"></img>      
    <p style="text-align: center">The OTP is <b>${generatedOTP}</b></p>
    <p style="text-align: center">Thank you for signing up!</p>
    `
    // Making Email Data
    const config = {
      SecureToken: `${process.env.REACT_APP_EMAIL_SERVER_ID}`,
      To: email,
      From: "s.pradhan@vartservices.com",
      Subject: "Email Verification",
      Body: emailBody
    }

    if (window.Email) {
      window.Email.send(config).then(
        message => {
          if (message === "OK") {
            Swal.fire({
              title: "Email Verification!",
              text: `Please enter OTP send on ${email}`,
              input: 'text',
              showCancelButton: false,
              confirmButtonText: 'Verify!'
            }).then((result) => {
              // checking OTP
              if (result.value == generatedOTP) {
                // checking previous data
                SheetDB.read(`https://sheetdb.io/api/v1/${process.env.REACT_APP_API_ID}`, { search: { email: email, reedemMonthYear: currentMonthYear } }).then(function (result) {
                  if (result == "" || result === "") {
                    // if no data found then insert one
                    fetch(`https://sheetdb.io/api/v1/${process.env.REACT_APP_API_ID}`, {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        data: [
                          {
                            'id': "INCREMENT",
                            'firstName': firstName,
                            'lastName': lastName,
                            'email': email,
                            'address': address,
                            'apartment': apartment,
                            'city': city,
                            'province': province,
                            'zip': zip,
                            'hat': hat,
                            'reedemMonthYear': currentMonthYear,
                          }
                        ]
                      })
                    })
                    // registered successfully
                    Swal.fire({
                      title: 'You are successfully registered',
                      icon: 'success',
                      confirmButtonText: 'Done',
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        window.location = 'https://www.luckylandslots.com'
                      }
                    })
                  } 
                  // if data is fetched then you cant register
                  else {
                    Swal.fire(
                      'Error!',
                      'You have already reedem the product for this month',
                      'error'
                    )
                  }
                });
              }
              
              else if (result.value == undefined) { }
              //if entered incorrect OTP
              else if (result.value != generatedOTP) {
                Swal.fire(
                  'Invalid OTP',
                  '',
                  'error'
                )
              }
            });
          }
        }
      );
    }

  }

  return (
    <>
      <div className='body_bg'>
        <div className='heading_bg'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-none d-md-block d-lg-block d-xl-block'>
              <img src="/images/badge1.png" alt="" />
              <h2 className='text-white heading_header'>Play for Fun <br />
                with <span className='heading_h2'>Gold Coin!</span></h2>
            </div>
            <div className='something '>
              <a href='https://www.luckylandslots.com'><img src="/images/Luckyland-2.png" className='luckland_img ' width={300} height={200} /></a>
            </div>
            <div className='d-none d-md-block d-lg-block d-xl-block'>
              <h2 className='text-white'>Play with <br /><span className='heading_prizes'>Sweeps Coins<sup>tm</sup></span> <br /> for prizes!</h2>
              <img className='heading_img2' src="./images/badge2.png " width={100} height={100} />
            </div>
          </div>
        </div>

        <div className='text-center text-white my-5'>
          <h1 className='enter_info  '>Enter your information to receive your FREE Diamond Duck merchandise!</h1>
          <p className='para'>This offer is only available for a limited time. Please allow 4-6 weeks to receive your items.</p>
        </div>
        {/* 
        <div className=''> */}
        <div className='row '>
          <div className='col-lg-5 col-sm-12 col-md-4 text-white  relative'>
            <img className='lady_img ' src="/images/lady.png" />
            <img className='duck d-none d-lg-block d-xl-block d-xxl-block' src="/images/duck.png" />
          </div>
          <div className='col-lg-7 col-sm-12 col-md-8 text-white'>
            <div>
              <form className='p-5 form_data1' >

                <div className='p-1'>
                  <label className='mylabel'>First Name*</label> <br />
                  <input className="inuput" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>Last Name*</label> <br />
                  <input type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>Email*</label> <br />
                  <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
                </div>

                <div className='p-1'>
                  <label className='mylabel'>Address*</label> <br />
                  <input type="text" onChange={(e) => setAddress(e.target.value)} value={address} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>Apartment, suite, etc</label> <br />
                  <input type="text" onChange={(e) => setApartment(e.target.value)} value={apartment} />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>City*</label> <br />
                  <input type="text" onChange={(e) => setCity(e.target.value)} value={city} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>Province*</label> <br />
                  <input type="text" onChange={(e) => setProvince(e.target.value)} value={province} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>ZIP/postal code*</label> <br />
                  <input type="text" onChange={(e) => setZip(e.target.value)} value={zip} required />
                </div>
                <div className='p-1'>
                  <label className='mylabel'>Hat*</label> <br />
                  <select onChange={(e) => setHat(e.target.value)} value={hat}>
                    <option>Select</option>
                    <option>Baseball Hat</option>
                    <option>Bucket Hat</option>
                  </select>
                </div><br />
                <a type='submit' onClick={handleSubmit}><img src="./images/btn.png " className='submitbut my-5' width={185} height={60} /></a>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}


        <div>
          <div className=' img-fluid'>
            <img src="./images/win-pile.png" className="diamond_duck" />
          </div>

          <div className='playnow'>

            <a href='https://www.luckylandslots.com'><img className='playnow_img' src="./images/play.png" /></a>
          </div>
          <img className="line" src="./images/line.png" />

          <div className='container all_details'>
            <p>In accordance with clause 3.1(j) of the LuckyLand Slots Terms of Service, you must not sell or trade for value, or seek to sell or trade for value, or accept as a
              sale or trade for value, any Merchandise provided to you by LuckyLand Slots. Doing so is a breach of the warranties you have provided and places your account at risk of permanent deactivation.</p>
            <p>If you have any questions, please contact us at <a href='mailto:support@luckylandslots.com' style={{ color: "#059BE" }}>support@luckylandslots.com.</a></p>
            <p>VGW Group. No purchase necessary. Void where prohibited by law. 18+</p>
            <p>Postal Alternative Method of Entry:</p>
            <p>Receive free Sweeps Coins by obtaining a Postal Request Code and sending a handwritten request that meets the requirements specified in our Sweeps Rules to:</p>
            <p>VGW LUCKYLAND INC.</p>
            <p>LUCKYLAND SLOTS SWEEPSTAKES DEPARTMENT
              PO BOX #8486
              PORTSMOUTH, NH 03801</p>
            <p>For more details see our <a href='#' style={{ color: "#059BE" }}>Sweeps Rules.</a></p>

            <p><a href='#' style={{ color: "#059BE" }}>Terms of Service</a> © 2023 VGW US Inc. :: 1840 Folsom Street, Suite 302, Boulder, CO, 80302:: All rights reserved</p>

          </div>
          <div className='last_part '>
            <h2 className='text-white'>Luckyland : part of VGW family</h2>
            <img src="./images/logos.png " className='alllogos' />
          </div>
        </div>
      </div>
      <div className='footer_div'>
        <div className=''>

          <p>Copyright 2019 LuckyLand Slots - All Rights Reserved -
            <a href="/">Terms of Services</a> - <a href="/">Privacy Policy</a>  - <a href="/">Contact Us</a> <br />
            No Purchase necessary subject to Terms of Service, void where prohibited by law.</p>

        </div>

        <div className=''>

          <ion-icon style={{
            color: "#fff",
            fontSize: "23px",
            padding: "5px",
            borderRadius: "10px ",
            backgroundColor: "#336", marginLeft: "10px"
          }} name="logo-facebook" > </ion-icon>
          <ion-icon style={{
            color: "#fff",
            fontSize: "23px",
            padding: "5px",
            borderRadius: "10px ",
            backgroundColor: "#059BE5", marginLeft: "10px"
          }} name="logo-twitter"></ion-icon>
        </div>
      </div>
    </>
  )
}

export default Landlord